<div class="container">
  <div class="container-banner">
    <h1>FAQ</h1>
    <div class="banner-content">
      <h2>
        We hope you'll find your answer here. If not, then feel free to
        <a href="/contact">contact</a> the Planet VG Chefs.
      </h2>
    </div>
  </div>

  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Do I need a Planet VG account?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Yes. You need a Planet VG account to grab deals, collect stamps and redeem your rewards.
        First download the Planet VG app. Open the app. Press <strong>Sign Up</strong> to create an account.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Can I grab a deal multiple times?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        No, you can't. You can only grab a deal once.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> Can I join a loyalty campaign again after completing it? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Yes, you can. 
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> When will I get a voucher? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        There are a couple ways to earn a voucher:
      </p>
      <ul>
        <li>
          Grab a deal. You will immediately receive a voucher.
        </li>
        <li>
          Collect all stamps for a loyalty campaign. Then you will receive a voucher.
        </li>
        <li>
          Be a club member. When your favorite club creates a new deal. Then you immediately receive a voucher.
        </li>
      </ul>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> Can I use a voucher multiple times? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        No, you can't. You can only redeem your reward once.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> Can the club see my account details? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        No, they can't see your account details.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> Why is my favorite club not on Planet VG? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        They are welcome to join the club. Let them know about us. 
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> What is your privacy policy? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        You can find our privacy policy on the
        <a href="/privacy-policy">Privacy Policy page</a>.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> What are the terms of service? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        You can find our user terms on the <a href="/user-terms">User Terms page</a> here.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-component-background">
      <mat-expansion-panel-header>
        <mat-panel-title> Still have questions? </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        If you still have questions, feel free to contact us at any time. You
        can find the contact form on this page
        <a href="/contact">Contact page</a>.
      </p>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<app-bottom-menu></app-bottom-menu>
