<div class="container">
  <div class="container-content">
    <div class="container-content-banner">
      <h1>How it works</h1>
      <h2>
        Planet VG empowers you to create stronger connections with the
        companies you want to support, whether they are new or existing.
      </h2>
      <h3>
        Easily find deals, loyalty campaigns and redeem
        rewards using our climate-, animal- and user-friendly app.
      </h3>
    </div>
  </div>
</div>
