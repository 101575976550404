<div
  [ngClass]="{
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }"
>
  <h1>About Planet VG</h1>
  <img src="/assets/img/planetvg_icon.png" width="150px" alt="planetvg-logo" />
  <div class="banner-content">
    <h2>
      We empower you to build stronger and lasting connections with climate
      conscious and vegan businesses by providing you climate- and
      animal-friendly deals and loyalty campaigns.
    </h2>
  </div>

  <div
    [ngClass]="{
      'container-card-row-large': isLarge(),
      'container-card-row-medium': isMedium(),
      'container-card-row-small': isSmall(),
      'container-card-row-xsmall': isXSmall()
    }"
  >
    <mat-card
      appearance="outlined"
      class="container-banner-profile mat-component-background mat-elevation-z2"
    >
      <mat-card-header>
        <mat-card-title>Why do we need to care so much?</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <div class="container-column">
          <p>
            Hi! I'm Iskandar, the founder of Planet VG (pronounce it like:
            <em><strong>Fiji</strong></em
            >. You know, that sinking island). This is me sabotaging a goose
            hunt as a volunteer with
            <a href="https://www.animalrights.nl" target="_blank" rel="noopener"
              >Animal Rights</a
            >. While sabotaging hunts made direct impact for those geese, I've
            always felt that there was another way for me to help save animals
            and the planet.
          </p>
          <p>
            With Planet VG I have found my way to do my part on saving the
            planet and making a better world for animals.
          </p>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card
      appearance="outlined"
      class="mat-component-background mat-elevation-z2"
    >
      <img
        mat-card-image
        src="/assets/img/profile.jpg"
        alt="Iskandar-Huntsab"
      />
      <mat-card-content
        ><div class="container-profile-content">
          <p>Iskandar, Founder</p>
        </div></mat-card-content
      >
    </mat-card>
  </div>
  <div class="container-card">
    <mat-card
      appearance="outlined"
      class="mat-component-background mat-elevation-z2"
    >
      <mat-card-header>
        <mat-card-title>So, what does VG stand for?</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <div class="container-column">
          <p>
            VG means a couple of things to me, and hopefully after reading this
            to you as well.
          </p>
          <p>
            First, you pronounce it like <strong><em>Fiji</em></strong
            >. Fiji is one of the many islands who are in big climate trouble.
            If we continue walking down this current path, we not only destroy
            the animals’ habitat but ours as well. We need to start caring so
            much that we will be able to change our path.
          </p>
          <p>
            Second, I love going out to restaurants and I'm always looking for
            the <strong>(vg)</strong> or vegan sign on the menu. It stands for
            the vegan way of life. Which means we need to be more compassionate
            to the planet we live on, animals and people we live with and strive
            for peace and freedom to all of us.
          </p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="container-card">
    <mat-card
      appearance="outlined"
      class="mat-component-background mat-elevation-z2"
    >
      <mat-card-header>
        <mat-card-title>What does Planet VG bring to the table?</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <div class="container-column">
          <p>
            Planet VG is a company that wants to empower like-minded people in
            their vegan way of life or business. We, earthlings, have difficult
            times ahead and we face hard challenges. But we can't change the
            world alone.
          </p>
          <p>
            We need to walk this path <strong>together</strong>. We need to
            create strong connections amongst ourselves to reinforce our values
            and build that future we so desire. We need every bit of help we can
            afford in saving the things we highly value.
          </p>
          <p>
            In the past my biggest concern was going to a nice vegan restaurant
            or store, only to find out that it was permanently closed. I needed
            to do something about this. I created Planet VG to give you a place
            to discover new vegan restaurants or even re-kindle interest in
            existing ones. And now, there is a platform for like-minded people
            to connect with eachother and build a better world together.
          </p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="container-card">
    <mat-card
      appearance="outlined"
      class="mat-component-background mat-elevation-z2"
    >
      <mat-card-header>
        <mat-card-title>Our Mission</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <div class="container-column">
          <p>
            Planet VG empowers all climate conscious and vegan people and
            businesses alike to change the world for the better. Our goal is to
            bring those people closer together. These bonds are needed if we
            want to create a better place for animals and people as well. A
            place where everyone is welcome, included, and treated fairly.
          </p>
          <p>
            We need to <strong>(vg)</strong> the planet. So, let’s start today.
            And support your favorite <strong>clubs</strong>.
          </p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<app-bottom-menu></app-bottom-menu>
