<div
  [ngClass]="{
    'container-xlarge': isXLarge(),
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }"
>
  <div
    [ngClass]="{
      'container-content-xlarge': isXLarge(),
      'container-content-large': isLarge(),
      'container-content-medium': isMedium(),
      'container-content-small': isSmall(),
      'container-content-xsmall': isXSmall()
    }"
  >
    <div
      [ngClass]="{
        'content-xlarge': isXLarge(),
        'content-large': isLarge(),
        'content-medium': isMedium(),
        'content-small': isSmall(),
        'content-xsmall': isXSmall()
      }"
    >
      <h1>Let's start saving</h1>
      <h2>
        Planet VG is the climate conscious and animal friendliest way for you
        to grab deals, collect stamps and be rewarded for your support.
      </h2>

      <div
        [ngClass]="{
          'container-row-get-apps-xlarge': isXLarge(),
          'container-row-get-apps-large': isLarge(),
          'container-row-get-apps-medium': isMedium(),
          'container-row-get-apps-small': isSmall(),
          'container-row-get-apps-xsmall': isXSmall()
        }"
      >
        <span
          ><a href=""
            ><img
              class="app-store-icon"
              src="/assets/img/google-play-badge.png"
              alt="Planet VG App - Google Play Store" /></a
        ></span>
        <span
          ><a href=""
            ><img
              class="app-store-icon"
              src="/assets/img/apple-app-store-badge.png"
              alt="Planet VG App - Apple App Store" /></a
        ></span>
      </div>
    </div>
  </div>
</div>
